import React from 'react';
import styled from 'styled-components';

import LinkButton from '../components/Buttons/LinkButton';
import Feature from '../components/Feature/Feature';
import HeroBackground from '../assets/img/home-hero-background.jpg';
import VrGirl from '../assets/img/vr-girl.png';
import ExamplesBackground from '../assets/img/home-examples-background.png';
import {Image360Icon, AddIcon, AnalyticsIcon, ChecklistIcon, DragDropIcon, MediaIcon, ScreenIcon } from '../assets/icons';
import SEO from '../components/SEO/SEO';

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25.6rem 2.4rem;
  background: url(${VrGirl}) top right no-repeat, url(${HeroBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface) var(--border-radius-surface) 0 0;
  @media only screen and (max-width: 1366px) {
    background: url(${VrGirl}) top right / 60rem no-repeat, url(${HeroBackground}) center / cover no-repeat;
  }
  @media only screen and (max-width: 1200px) {
    background: url(${VrGirl}) top 2rem right / 50rem no-repeat, url(${HeroBackground}) center / cover no-repeat;
  }
  @media only screen and (max-width: 1000px) {
    background: url(${VrGirl}) top 4rem right / 50rem no-repeat, url(${HeroBackground}) center / cover no-repeat;
  }
  @media only screen and (max-width: 800px) {
    background: url(${VrGirl}) top 4rem right / 40rem no-repeat, url(${HeroBackground}) center / cover no-repeat;
  }
`;

const TitleSection = styled.section`
  width: 100%;
  max-width: var(--page-content-width);
  margin-bottom: 32rem;
  @media only screen and (max-width: 1200px) {
    h1, p {
      max-width: 46rem;
    }
  }
  @media only screen and (max-width: 800px) {
    h1, p {
      max-width: 36rem;
    }
  }
`;

const AboutSection = styled.section`
  width: 100%;
  max-width: var(--page-content-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .caption {
    color: var(--color-yellow);
  }
  p {
    max-width: 56rem;
    margin-bottom: var(--margin-text-bottom);
    color: var(--color-body-light);
  }
`;

const FeaturesSection = styled.section`
  position: relative;
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: var(--color-surface-light);
  border-radius: var(--border-radius-surface);
`;

const FeaturesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6.4rem 2rem;
  div:last-child {
    grid-column: 1 / 3;
  }
  margin-bottom: 6.4rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

const ExamplesSection = styled.section`
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: url(${ExamplesBackground}) center / cover no-repeat;
  border-radius: 0 0 var(--border-radius-surface) var(--border-radius-surface);
`;

const ExamplesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto;
  .caption {
    color: var(--color-yellow);
  }
  p {
    color: var(--color-body-light);
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 6.4rem 0;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const UseCases = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4.8rem;
  margin-bottom: 12rem;
  h3 {
    color: var(--color-body-light);
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO title='The easiest path to a virtual experience' description='Upload 360 images and videos and create interaction points for an unforgettable virtual experience! See how veerXR can enhance your business.' location={location} />
      <Hero>
        <TitleSection>
          <h1>veerXR - the easiest path to a virtual experience</h1>
          <p className='subtitle'>You don’t have to be a software engineer to create unique VR interactive experiences!</p>
          <LinkButton type='internal' href='/contact-us/'>Request a FREE demo</LinkButton>
        </TitleSection>
        <AboutSection>
          <div className='caption'>About the platform</div>
          <h2>360 experiences for desktop,<br/>mobile devices, or VR</h2>
          <p>Create unique interactive experiences to enrich your business! Use our veerXR platform to put together 360 experiences for desktop, mobile devices, or VR. No coding knowledge, downloads, or installation required!</p>
          <p>veerXR enables you to create virtual tours with 360 scenes and additional content such as text, audio, and video. You can even include tests and quizzes with multiple-choice questions! An efficient analytics system helps track the data of each experience and each user through an experience.</p>
        </AboutSection>
      </Hero>

      <FeaturesSection>
        <FeaturesSectionContent>
          <div className='caption'>Create your own reality</div>
          <h2>Features</h2>
          <Features>
            <Feature icon={DragDropIcon} title='Simple Drag & Drop'>Our intuitive interface enables you to create a virtual tour through a drag and drop system. No coding necessary!</Feature>
            <Feature icon={Image360Icon} title='Upload Your Own 360 Image'>Our platform supports any panoramic equirectangular JPG or PNG file.</Feature>
            <Feature icon={ScreenIcon} title='Home Screen'>Select a starting screen that best fits your experience: a map or an image.</Feature>
            <Feature icon={MediaIcon} title='Embed Audio & Video'>Through using our Information Points, enrich your 360 scenes with text information, audio, or video files to wow your consumers!</Feature>
            <Feature icon={ChecklistIcon} title='Questionnaire'>Evaluate the performance of your employees or conduct a customer survey by adding Question Points to your 360 scenes!</Feature>
            <Feature icon={AnalyticsIcon} title='Analytics'>Our easy-to-follow analytics enable you to monitor your consumers’ behavior in the virtual tour and gain invaluable insights into their interests and knowledge.</Feature>
            <Feature icon={AddIcon} title='Add a New Feature'>If you don’t see a feature you need in our list, don’t hesitate to contact us and request a quote for adding a new feature for your business!</Feature>
          </Features>
          <LinkButton type='internal' href='/features/' margin='0 0 0 auto' backgroundColor='var(--color-green)' textColor='var(--color-body-dark)'>Learn more</LinkButton>
        </FeaturesSectionContent>
      </FeaturesSection>

      <ExamplesSection>
        <ExamplesSectionContent>
          <div className='caption'>For every use case</div>
          <h2>Examples of Using Our Platform</h2>
          <p>Take your customers on guided tours around the most impressive global landmarks and exhibitions. No travel expenses, no exposure to crowds or unfavorable weather, and social-distancing approved!</p>
          <VideoContainer>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/q7wotXgrzxk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </VideoContainer>
          <UseCases>
            <div>
              <h3>Remote training</h3>
              <p>Reduce work disruption and health hazards by safely training your employees in a 360 virtual reality setting</p>
            </div>
            <div>
              <h3>E-learning</h3>
              <p>Teach your students virtually any subject by moving away from traditional textbooks and allowing them to experience the lesson in an interactive environment.</p>
            </div>
            <div>
              <h3>Culture, History, and Travel Tours</h3>
              <p>Take your customers on guided tours around the most impressive global landmarks and exhibitions. No travel expenses, no exposure to crowds or unfavorable weather, and social-distancing approved!</p>
            </div>
            <div>
              <h3>Entertainment</h3>
              <p>Wow your friends and employees with an exciting virtual experience you will all enjoy!</p>
            </div>
            <div>
              <h3>Retail Solutions</h3>
              <p>Present your products in their best light in an immersive, cutting-edge environment that your consumers won’t forget any time soon.</p>
            </div>
            <div>
              <h3>And more...</h3>
            </div>
          </UseCases>
          <LinkButton type='internal' href='/contact-us/' margin='0 auto'>FREE demo of a virtual experience</LinkButton>
        </ExamplesSectionContent>
      </ExamplesSection>
    </>
  )
}

export default IndexPage;
